$(function(){
    $('[data-moveTo]').on('click', function(){
        var speed = 500;
        var href= $(this).attr('href');
        var target = $(href == '#' || href == '' ? 'html' : href);
        var headerHeight = $('.p-header').outerHeight();
        var pcPosition = target.offset().top - headerHeight;
        var spPosition = target.offset().top - headerHeight;

        if ($(window).width() <= 1024) {
            $('html, body').animate({scrollTop:spPosition}, speed, 'easeInQuad');
            return false;
        } else {
            $('html, body').animate({scrollTop:pcPosition}, speed, 'easeInQuad');
            return false;
        }
    });
});
